import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Breadcrumb from "../components/breadcrumb"

const LeLabelPage = ({ data }) => (
  <Layout>
    <Seo title="Le Label" breadcrumb={[
      { url: "le-label", name: "Le Label"},
    ]} />
    <Breadcrumb paths={[{url: null, name:"Le label"}]} />
    <h1 className="h1">Le Label</h1>
    <div
      className="text md:w-3/4 html-content"
      dangerouslySetInnerHTML={{ __html: data.strapiLeLabel.content }}
    ></div>
  </Layout>
)

export default LeLabelPage

export const pageQuery = graphql`
  query LeLabelQuery {
    strapiLeLabel {
      content
    }
  }
`
